import React from 'react';
import styles from './sapin.module.css';
import animalsInfo from '../../animalsInfos';

export default function Sapin({ onAnimalClick }) {
    // Ajoutez ces couleurs au début du composant
    const borderColors = [
        '#ffea9e', // vert sapin


    ];

    const shuffleColors = () => {
        const shuffled = [...borderColors];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    // Créer un tableau de couleurs mélangées pour tous les animaux
    const randomizedColors = shuffleColors();

    // Positions absolues sur le sapin pour les 23 images
    const imagePositions = [
        // Étoile au sommet
        { top: '15%', left: '49%', transform: 'translateX(-50%)' },

        // Première rangée (3 images, espacement uniforme entre 35% et 65%)
        { top: '23%', left: '34%' },
        { top: '27%', left: '49%' },
        { top: '23%', left: '66%' },

        // Deuxième rangée (3 images, espacement uniforme entre 30% et 70%)
        { top: '36%', left: '27%' },
        { top: '38%', left: '49%' },
        { top: '36%', left: '74%' },

        // Troisième rangée (4 images, espacement uniforme entre 25% et 75%)
        { top: '47%', left: '20%' },
        { top: '45%', left: '38%' },
        { top: '44%', left: '62%' },
        { top: '47%', left: '80%' },

        // Quatrième rangée (5 images, espacement uniforme entre 20% et 80%)
        { top: '58%', left: '10%' },
        { top: '55%', left: '30%' },
        { top: '55%', left: '50%' },
        { top: '58%', left: '70%' },
        { top: '58%', left: '90%' },

        // Cinquième rangée (7 images, espacement uniforme entre 15% et 85%)
        { top: '78%', left: '0%' },
        { top: '72%', left: '21%' },
        { top: '65%', left: '36%' },
        { top: '73%', left: '49%' },
        { top: '68%', left: '64%' },
        { top: '68%', left: '81%' },
        { top: '78%', left: '100%' }
    ];



    return (
        <div className={styles.sapinContainer}>
            <div className={styles.Calque_1_116_2}>
                <div className={styles.ObjetGénératif_116_3}>
                    <div className={styles.sapinWrapper}>
                        <img src="/png/sapin.png" alt="sapin" />
                        <div className={styles.bouleContainer}>
                            {Array.from({ length: 23 }, (_, index) => {
                                const animal = animalsInfo[index];
                                const randomColor = randomizedColors[index % borderColors.length];

                                return (
                                    <div
                                        key={index}
                                        className={styles.imageContainer}
                                        style={{
                                            top: imagePositions[index].top,
                                            left: imagePositions[index].left,
                                            width: '7vmin',
                                            height: '7vmin',
                                            border: `1px solid ${randomColor}`,
                                            borderRadius: '50%',
                                            boxShadow: `0 0 10px ${randomColor}`,
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.boxShadow = `0 0 15px ${randomColor}`;
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.boxShadow = `0 0 10px ${randomColor}`;
                                        }}
                                    >
                                        <button
                                            onClick={() => onAnimalClick(index)}
                                            className="w-full h-full relative group"
                                            title={animal.name}
                                        >
                                            <img
                                                src={animal.picture}
                                                alt={animal.name}
                                                className={styles.dayImage}
                                                style={{
                                                    borderRadius: '50%',
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all duration-300 rounded-full" />
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}