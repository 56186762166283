import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useGLTF,
  PerspectiveCamera,
  useAnimations,
  useTexture, Sparkles
} from "@react-three/drei";
import {
  LoopOnce,
  ConeGeometry,
  AnimationUtils,
  SRGBColorSpace,
  AdditiveBlending,
} from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { easing } from "maath";
import DogModel from "./DogModel";
import CatModel from "./CatModel";
import animalsInfo from "../animalsInfos";


export default function Modelisation({ discover, onCameraAnimationEnd, date, isCameraLocked }) {
  const group = useRef();
  const { nodes, animations, materials } = useGLTF("/gltf/refuge4.glb");
  const { mixer, clips } = useAnimations(animations, group);
  const isMobile = window.innerWidth <= 768; // Définissez une largeur maximale pour considérer un appareil comme mobile
  console.log('discover', clips);
  const particletexture = useTexture("/textures/particle.webp");
  const terrainColorMap = useTexture("/textures/baked_terrain_color.webp");
  const terrainLightMap = useTexture("/textures/baked_terrain_light.webp");
  const generalColorMap = useTexture("/textures/baked_general_color.webp");
  const generalLightMap = useTexture("/textures/baked_general_light.webp");
  const matcapTexture = useTexture("/textures/0A0A0A_A9A9A9_525252_747474-128px.webp");
  const animals = useTexture("/textures/animals.webp")
  const matcapanimals = useTexture("/textures/7A7A7A_D9D9D9_BCBCBC_B4B4B4.jpg")
  animals.flipY = false
  terrainColorMap.flipY = false;
  terrainLightMap.flipY = false;
  generalColorMap.flipY = false;
  generalLightMap.flipY = false;
  terrainColorMap.encoding = SRGBColorSpace;
  terrainLightMap.encoding = SRGBColorSpace;
  generalColorMap.encoding = SRGBColorSpace;
  generalLightMap.encoding = SRGBColorSpace;
  terrainColorMap.colorSpace = SRGBColorSpace;
  terrainLightMap.colorSpace = SRGBColorSpace;
  generalColorMap.colorSpace = SRGBColorSpace;
  generalLightMap.colorSpace = SRGBColorSpace;
  generalLightMap.channel = 1;

  const { camera } = useThree();
  const cameraMode = useRef();
  const target = useRef();
  const animalPosition = useRef();
  const animal = useRef();
  const currentCameraPos = useRef({ x: 0, y: 0, z: 0 });
  const currentTargetPos = useRef({ x: 0, y: 0, z: 0 });
  const pointer = useRef();
  const [cameraAnimationEnd, setCameraAnimationEnd] = useState(false);

  //buffer geometry for central tree particles
  const pointsPositions = useMemo(() => {
    const baseGeometry = new ConeGeometry(2, 5.5, 16, 16);
    return baseGeometry.getAttribute("position").clone();
  }, []);

  //camera orbit control effect and camera responsiveness
  useEffect(() => {
    pointer.current = { x: 0.5, y: 0.5 };

    const handleMouseMove = (event) => {
      pointer.current.x = (event.clientX / window.innerWidth) * 2 - 1;
      pointer.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
    };
    window.addEventListener("mousemove", handleMouseMove);

    updateCameraZoom();
    window.addEventListener("resize", updateCameraZoom);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", updateCameraZoom);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //change camera zoom depending on device orientation
  const updateCameraZoom = () => {
    const isPortrait = window.innerHeight > window.innerWidth;
    camera.zoom = isPortrait ? 0.5 : 1;
    camera.updateProjectionMatrix();
    cameraMode.current.zoom = isPortrait ? 0.5 : 1;
    cameraMode.current.updateProjectionMatrix();
  };

  //trigger camera animation when PLAY button is clicked
  useEffect(() => {
    console.log(isCameraLocked);

    if (isCameraLocked) {
      mixer.stopAllAction();
      let timeout;
      const frameLength = 100;
      timeout = setTimeout(() => {
        clips.forEach((clip) => {
          let action;

          if (clip.name === "animal_position_animation") {
            const anim = AnimationUtils.subclip(
              clip,
              "AnimalPositionAction",
              (date - 1) * frameLength + 1,
              frameLength * date - 1
            );
            action = mixer.clipAction(anim);
          }

          if (action) {
            action.clampWhenFinished = true;
            action.loop = LoopOnce;
            action.timeScale = 2;
            action.play();
          }
        });
      }, 0);

      // Positionner la caméra à la position verrouillée
      cameraMode.current.position.set(30, 11, 30);
      cameraMode.current.lookAt(0, 0, 0);
      setCameraAnimationEnd(true);

      // Afficher l'animal et sa popup même si la caméra est verrouillée
      handleCameraAnimationEnd();
    } else {
      // Initialiser la position de la caméra au démarrage
      currentCameraPos.current = cameraMode.current.position.clone();
      currentTargetPos.current = target.current.position.clone();

      // Restaurer la position de la caméra lorsque déverrouillée
      cameraMode.current.position.copy(currentCameraPos.current);
      target.current.position.copy(currentTargetPos.current);

      setCameraAnimationEnd(false);
      mixer.stopAllAction(); // Arrêter toutes les actions d'animation

      let timeout;
      const frameLength = 100;
      if (discover) {
        timeout = setTimeout(() => {
          clips.forEach((clip) => {
            let action;

            if (clip.name === "camera_animation") {
              const anim = AnimationUtils.subclip(
                clip,
                "CameraAction",
                (date - 1) * frameLength + 1,
                frameLength * date - 1
              );
              action = mixer.clipAction(anim);
            } else if (clip.name === "camera_target_animation") {
              const anim = AnimationUtils.subclip(
                clip,
                "TargetCameraAction",
                (date - 1) * frameLength + 1,
                frameLength * date - 1
              );
              action = mixer.clipAction(anim);
            } else if (clip.name === "animal_position_animation") {
              const anim = AnimationUtils.subclip(
                clip,
                "AnimalPositionAction",
                (date - 1) * frameLength + 1,
                frameLength * date - 1
              );
              action = mixer.clipAction(anim);
            }

            if (action) {
              action.clampWhenFinished = true;
              action.loop = LoopOnce;
              action.timeScale = 1;
              action.play();
            }
          });
        }, 500);
      }
      mixer.addEventListener("finished", () => handleCameraAnimationEnd());

      return () => {
        clearTimeout(timeout);
        mixer.removeEventListener("finished", () => handleCameraAnimationEnd());
      };
    }
  }, [discover, date, isCameraLocked]);

  const handleCameraAnimationEnd = () => {
    onCameraAnimationEnd(animalsInfo[date - 1].breed);
    setCameraAnimationEnd(true);
    currentCameraPos.current = cameraMode.current.position.clone();
    currentTargetPos.current = target.current.position.clone();
  };

  useFrame((state, delta) => {
    if (discover || isCameraLocked || cameraAnimationEnd) {
      animal.current.position.copy(animalPosition.current.position);
      animal.current.rotation.copy(animalPosition.current.rotation);
    }


    cameraMode.current.lookAt(target.current.position);
    if (pointer.current) {
      if (cameraAnimationEnd) {
        easing.damp3(
          cameraMode.current.position,
          animalPosition.current.rotation.y === 0
            ? [
              Math.sin(pointer.current.x / 2) * -1 + currentCameraPos.current.x,
              Math.sin(pointer.current.y / 2) * -0.2 +
              currentCameraPos.current.y,
              currentCameraPos.current.z,
            ]
            : [
              currentCameraPos.current.x,
              Math.sin(pointer.current.y / 2) * -0.2 +
              currentCameraPos.current.y,
              Math.sin(pointer.current.x / 2) * 1 + currentCameraPos.current.z,
            ],
          0.5,
          delta
        );
        easing.damp3(
          target.current.position,
          animalPosition.current.rotation.y === 0
            ? [
              Math.sin(pointer.current.x / 2) * 2 + currentTargetPos.current.x,
              Math.sin(pointer.current.y / 2) * 0.4 +
              currentTargetPos.current.y,
              currentTargetPos.current.z,
            ]
            : [
              currentTargetPos.current.x,
              Math.sin(pointer.current.y / 2) * 0.4 +
              currentTargetPos.current.y,
              Math.sin(pointer.current.x / 2) * -2 + currentTargetPos.current.z,
            ],
          0.5,
          delta
        );
      }
      if (isCameraLocked) {
        easing.damp3(
          cameraMode.current.position,
          animalPosition.current.rotation.y === 0
            ? [
              Math.sin(pointer.current.x / 2) * -9 + currentCameraPos.current.x,
              Math.sin(pointer.current.y / 2) * -9.4 +
              currentCameraPos.current.y,
              currentCameraPos.current.z,
            ]
            : [
              currentCameraPos.current.x,
              Math.sin(pointer.current.y / 2) * -0.4 +
              currentCameraPos.current.y,
              Math.sin(pointer.current.x / 2) * 2 + currentCameraPos.current.z,
            ],
          0.9,
          delta
        );
        easing.damp3(
          target.current.position,
          animalPosition.current.rotation.y === 0
            ? [
              Math.sin(pointer.current.x / 2) * 60 + currentTargetPos.current.x,
              Math.sin(pointer.current.y / 2) * 20.4 +
              currentTargetPos.current.y,
              currentTargetPos.current.z,
            ]
            : [
              currentTargetPos.current.x,
              Math.sin(pointer.current.y / 2) * 12.4 +
              currentTargetPos.current.y,
              Math.sin(pointer.current.x / 2) * -3 + currentTargetPos.current.z,
            ],
          0.5,
          delta
        );
      }
    }
  });


  return (
    <>
      {discover && (
        <group ref={animal}>
          {animalsInfo[date - 1].breed === "Chat" ? (
            <CatModel />
          ) : animalsInfo[date - 1].breed === "Chien" ? (
            <DogModel />
          ) : animalsInfo[date - 1].breed === "ChatChien" ? (
            <>
              <group position={[0.4, 0, 0]}>
                <DogModel />
              </group>
              <CatModel />
            </>
          ) : null}
        </group>
      )}
      <group ref={group} dispose={null} visible={true}>
        <group name="Scene">
          <mesh
            name="baked_terrain"
            geometry={nodes.baked_terrain.geometry}
            material={nodes.baked_terrain.material}
          >
            <meshBasicMaterial
              map={terrainColorMap}
              lightMap={terrainLightMap}
              lightMapIntensity={1.9}
              color={[1.5, 1.5, 1.5]}
            />
          </mesh>
          <mesh
            name="whiteLight"
            geometry={nodes.whiteLight.geometry}
            material={nodes.whiteLight.material}
          >
            <meshBasicMaterial color={[2, 2, 2]} />
          </mesh>
          <mesh name="baked" geometry={nodes.baked.geometry}>
            <meshBasicMaterial
              map={generalColorMap}
              lightMap={generalLightMap}
              lightMapIntensity={3.9}
              color={[1.5, 1.5, 1.5]}
            />

          </mesh>

          {[...Array(24)].map((number, index) => (
            <mesh
              name={"baked_number_" + String(index + 1)}
              geometry={nodes["baked_number_" + String(index + 1)].geometry}
              key={"baked_number_" + String(index + 1) + "_" + String(date)}
            >
              {date === index + 1 ? (
                <meshBasicMaterial color={[1.1, 1.05, 0.5]} />
              ) : (
                <meshBasicMaterial
                  map={generalColorMap}

                />
              )}
            </mesh>
          ))}
          <mesh
            name="interiors"
            geometry={nodes.interiors.geometry}
            material={nodes.interiors.material}
          >
            <meshBasicMaterial color={[1.1, 1.05, 0.5]} />
          </mesh>
          <PerspectiveCamera
            ref={cameraMode}
            name="Camera"
            makeDefault={true}
            far={413.8}
            near={0.1}
            fov={40}
            position={[80, 31.515, 80]}
            rotation={[-0.252, 0.766, 0.177]}
          />
          <group ref={animalPosition} name="animal_position"  >
            {!isMobile && (
              <Sparkles count={30} size={3} speed={0.9} position={[0, 1, 0]} />
            )}
          </group>
          <group
            name="Camera_target_1"
            ref={target}
            position={[0.427, 10.84, -0.145]}
          />
          <points position={[0.2, 3.5, 0]}>
            <bufferGeometry attach="geometry">
              <bufferAttribute
                attach="attributes-position"
                count={pointsPositions.count}
                array={pointsPositions.array}
                itemSize={3}
              />
            </bufferGeometry>
            <pointsMaterial
              color={[1.01, 1.0, 1.0]} // Couleur blanche
              size={1.5} // Augmenter la taille des points
              alphaMap={particletexture}
              transparent
              depthWrite={false}
              blending={AdditiveBlending}
            />
          </points>
          <points position={[0.2, 3.5, 0]} rotation={[0, 160.6, 0]}>
            <bufferGeometry attach="geometry">
              <bufferAttribute
                attach="attributes-position"
                count={pointsPositions.count}
                array={pointsPositions.array}
                itemSize={3}
              />
            </bufferGeometry>
            <pointsMaterial
              color={[0.01, 2.0, 0]} // Couleur verte
              size={1.0} // Augmenter la taille des points
              alphaMap={particletexture}
              transparent
              sizeAttenuation={true}
              depthWrite={false}
            />
          </points>
          <points position={[0.2, 3.5, 0]} rotation={[0, 100, 0]}>
            <bufferGeometry attach="geometry">
              <bufferAttribute
                attach="attributes-position"
                count={pointsPositions.count}
                array={pointsPositions.array}
                itemSize={3}
              />
            </bufferGeometry>
            <pointsMaterial
              color={[2, 0, 0]} // Couleur rouge
              size={1.2} // Augmenter la taille des points
              alphaMap={particletexture}
              transparent
              sizeAttenuation={true}
              depthWrite={false}
              blending={AdditiveBlending}
            />
          </points>




          <points position={[0, 17.655, 0]}>
            <bufferGeometry attach="geometry">
              <bufferAttribute
                attach="attributes-position"
                count={nodes.redLights.geometry.attributes.position.count}
                array={nodes.redLights.geometry.attributes.position.array}
                itemSize={3}
              />
            </bufferGeometry>
            <pointsMaterial
              color={[2, 0, 0]}
              size={0.5}
              alphaMap={particletexture}
              transparent
              sizeAttenuation={true}
              depthWrite={false}
              blending={AdditiveBlending}
            />
          </points>
          <points position={[0, 17.655, 0]}>
            <bufferGeometry attach="geometry">
              <bufferAttribute
                attach="attributes-position"
                count={nodes.greenLights.geometry.attributes.position.count}
                array={nodes.greenLights.geometry.attributes.position.array}
                itemSize={3}
              />
            </bufferGeometry>
            <pointsMaterial
              color={[0, 2, 0]}
              size={0.5}
              alphaMap={particletexture}
              transparent
              sizeAttenuation={true}
              depthWrite={false}
              blending={AdditiveBlending}
            />
          </points>
          <mesh
            name="Low_Poly_Kokoni_Dog"
            castShadow
            receiveShadow
            geometry={nodes.Low_Poly_Kokoni_Dog.geometry}
            material={nodes.Low_Poly_Kokoni_Dog.material}
            position={[15.709, 0.587, 2.966]}
            scale={1.358}>
            <mesh
              name="KokoniEyes"
              castShadow
              receiveShadow
              geometry={nodes.KokoniEyes.geometry}
              material={nodes.KokoniEyes.material}
            />
          </mesh>

          <mesh
            name="Low_Poly_Kokoni_Dog002"
            castShadow
            receiveShadow
            geometry={nodes.Low_Poly_Kokoni_Dog002.geometry}
            material={nodes.Low_Poly_Kokoni_Dog002.material}
            position={[12.701, 0.687, 12.757]}
            rotation={[Math.PI, -0.847, Math.PI]}
            scale={1.358}
          />
          <mesh
            name="Cat_Low"
            castShadow
            receiveShadow
            geometry={nodes.Cat_Low.geometry}
            material={nodes.Cat_Low.material}
            position={[-2.335, 1.684, 7.052]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.023}
          />
          <mesh
            name="Cat_Low001"
            castShadow
            receiveShadow
            geometry={nodes.Cat_Low001.geometry}
            material={nodes.Cat_Low001.material}
            position={[7.791, 0.824, 7.052]}
            rotation={[Math.PI / 2, 0, 0.628]}
            scale={0.023}
          />
          <mesh
            name="fat_cat_white"
            castShadow
            receiveShadow
            geometry={nodes.fat_cat_white.geometry}
            position={[-10.667, 3.40, 4.363]}
            rotation={[Math.PI / 2, 0, -0.071]}
            scale={0.03}
          >
            <meshMatcapMaterial map={matcapTexture} />
          </mesh>
        </group>
        <mesh
          name="Low_Poly_Chicken"
          castShadow
          receiveShadow
          geometry={nodes.Low_Poly_Chicken.geometry}
          position={[-6.581, 3.537, -9.751]}
          rotation={[0, -0.282, 0]}
          scale={1.115}
        >
          <meshBasicMaterial map={animals} />

        </mesh>
      </group >

    </>
  );
}
