import React, { useEffect, useRef } from "react";
import { Center, useAnimations, useGLTF, useTexture } from "@react-three/drei";
import animalsInfo from "../animalsInfos";

export default function AnimalCanvas({ hoveredNumber, date, discover }) {
  const catRef = useRef()
  const dogRef = useRef()

  const { nodes, materials, animations } = useGLTF("./gltf/Chat Anim2.glb")
  const { actions: catActions } = useAnimations(animations, catRef)

  const { nodes: n2, materials: m2, animations: a2 } = useGLTF("./gltf/Chien Anim.glb")
  const { actions: dogActions } = useAnimations(a2, dogRef)

  const dogmap = useTexture("./textures/LP_ShibaInu_1002_Diffuse.jpg")
  const catmap = useTexture("./textures/Fat_cat_calico_base.webp")
  catmap.flipY = false;


  const matcamanimals = useTexture("./textures/7A7A7A_D9D9D9_BCBCBC_B4B4B4.jpg")

  const animationsNames = ["jump", "Dog_Walk", "Chien1", "Dog_Walk"];
  const animationsNamesCat = ["Sit", "static", "walk", "Sit"];

  // Vérifiez que l'index est valide
  const animalInfo = animalsInfo[date - 1];
  const breed = animalInfo ? animalInfo.breed : null;

  useEffect(() => {
    if (!breed) return;

    if (hoveredNumber - 1 >= animationsNames.length || hoveredNumber <= 0) {
      return;
    }

    const cleanupFunctions = [];

    if (breed === "Chien") {
      const action = dogActions[animationsNames[hoveredNumber - 1]];
      action.reset().fadeIn(1).play();
      cleanupFunctions.push(() => action.fadeOut(1));
    } else if (breed === "Chat") {
      const action = catActions[animationsNamesCat[hoveredNumber - 1]];
      action.reset().fadeIn(1).play();
      cleanupFunctions.push(() => action.fadeOut(1));
    } else if (breed === "ChatChien") {
      const catAction = catActions[animationsNamesCat[hoveredNumber - 1]];
      const dogAction = dogActions[animationsNames[hoveredNumber - 1]];

      if (catAction && dogAction) {
        catAction.reset().fadeIn(1).play();
        dogAction.reset().fadeIn(1).play();

        cleanupFunctions.push(
          () => catAction.fadeOut(1),
          () => dogAction.fadeOut(1)
        );
      }
    }

    return () => {
      cleanupFunctions.forEach(cleanup => cleanup());
    };
  }, [hoveredNumber, dogActions, catActions, breed]);



  return (
    <>
      <group position={[0, -0.9, 0]}>
        {breed === "Chat" ? (
          <group
            ref={catRef}
            scale={0.85}
            rotation={[0, 0.5, 0]}
            visible={!discover}
          >
            <skinnedMesh
              name="Mesh_Chat"
              geometry={nodes.Mesh_Chat.geometry}
              skeleton={nodes.Mesh_Chat.skeleton}
            >
              <meshMatcapMaterial matcap={matcamanimals} map={catmap} /></skinnedMesh>
            <primitive object={nodes.c_pos} />
            <primitive object={nodes.c_foot_ikr} />
            <primitive object={nodes.c_leg_poler} />
            <primitive object={nodes.c_foot_ikl} />
            <primitive object={nodes.c_leg_polel} />
            <primitive object={nodes.root_refx} />
            <primitive object={nodes.c_foot_ik_dupli_001l} />
            <primitive object={nodes.c_leg_pole_dupli_001l} />
            <primitive object={nodes.c_foot_ik_dupli_001r} />
            <primitive object={nodes.c_leg_pole_dupli_001r} />
          </group>
        ) : breed === "Chien" ? (
          <group
            ref={dogRef}
            scale={0.8}
            rotation={[0, 1, 0]}
            visible={!discover}
          >
            <group name="Dog">
              <group name="Dog">
                <skinnedMesh
                  name="Dog"
                  geometry={n2.Dog.geometry}
                  skeleton={n2.Dog.skeleton}
                  material={m2.Dog}
                >
                  <meshMatcapMaterial matcap={matcamanimals} map={m2['lambert1.001'].map} /></skinnedMesh>
              </group>
            </group>
            <primitive object={n2.c_pos} />
            <primitive object={n2.c_foot_ikr} />
            <primitive object={n2.c_leg_poler} />
            <primitive object={n2.c_foot_ikl} />
            <primitive object={n2.c_leg_polel} />
            <primitive object={n2.root_refx} />
            <primitive object={n2.c_foot_ik_dupli_001l} />
            <primitive object={n2.c_leg_pole_dupli_001l} />
            <primitive object={n2.c_foot_ik_dupli_001r} />
            <primitive object={n2.c_leg_pole_dupli_001r} />
          </group>
        ) : breed === "ChatChien" ? (
          <>
            <group
              ref={catRef}
              scale={0.5}
              rotation={[0, 0.5, 0]}
              position={[-0.5, 0, 0]}
              visible={!discover}
            >
              <skinnedMesh
                name="Mesh_Chat"
                geometry={nodes.Mesh_Chat.geometry}
                skeleton={nodes.Mesh_Chat.skeleton}
              >
                <meshMatcapMaterial matcap={matcamanimals} map={catmap} /></skinnedMesh>
              <primitive object={nodes.c_pos} />
              <primitive object={nodes.c_foot_ikr} />
              <primitive object={nodes.c_leg_poler} />
              <primitive object={nodes.c_foot_ikl} />
              <primitive object={nodes.c_leg_polel} />
              <primitive object={nodes.root_refx} />
              <primitive object={nodes.c_foot_ik_dupli_001l} />
              <primitive object={nodes.c_leg_pole_dupli_001l} />
              <primitive object={nodes.c_foot_ik_dupli_001r} />
              <primitive object={nodes.c_leg_pole_dupli_001r} />
            </group>
            <group
              ref={dogRef}
              scale={0.5}
              rotation={[0, -1, 0]}
              position={[0.5, 0, 0]}
              visible={!discover}
            >
              <group name="Dog">
                <group name="Dog">
                  <skinnedMesh
                    name="Dog"
                    geometry={n2.Dog.geometry}
                    skeleton={n2.Dog.skeleton}
                    material={m2.Dog}
                  >
                    <meshMatcapMaterial matcap={matcamanimals} map={m2['lambert1.001'].map} /></skinnedMesh>
                </group>
              </group>
              <primitive object={n2.c_pos} />
              <primitive object={n2.c_foot_ikr} />
              <primitive object={n2.c_leg_poler} />
              <primitive object={n2.c_foot_ikl} />
              <primitive object={n2.c_leg_polel} />
              <primitive object={n2.root_refx} />
              <primitive object={n2.c_foot_ik_dupli_001l} />
              <primitive object={n2.c_leg_pole_dupli_001l} />
              <primitive object={n2.c_foot_ik_dupli_001r} />
              <primitive object={n2.c_leg_pole_dupli_001r} />
            </group>
          </>
        ) : null}
      </group>
    </>
  );
}
